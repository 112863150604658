export const GET_COMPANY_LIST_DATA_REQUEST_INIT = 'GET_COMPANY_LIST_DATA_REQUEST_INIT';
export const GET_COMPANY_LIST_DATA_REQUEST_DONE = 'GET_COMPANY_LIST_DATA_REQUEST_DONE';
export const GET_COMPANY_LIST_DATA_REQUEST_FAILURE = 'GET_COMPANY_LIST_DATA_REQUEST_FAILURE';

export const GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_INIT = 'GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_INIT';
export const GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_DONE = 'GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_DONE';
export const GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE = 'GET_ADD_COMPANY_PAGE_INITIALIZE_DATA_REQUEST_FAILURE';

export const GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_INIT = 'GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_INIT';
export const GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_DONE = 'GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_DONE';
export const GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_FAILURE = 'GET_FINANCE_DATA_BASED_COMPANY_ID_REQUEST_FAILURE';

export const GET_FINANCE_DATA_DOWNLOAD_REQUEST_INIT = 'GET_FINANCE_DATA_DOWNLOAD_REQUEST_INIT';
export const GET_FINANCE_DATA_DOWNLOAD_REQUEST_DONE = 'GET_FINANCE_DATA_DOWNLOAD_REQUEST_DONE';
export const GET_FINANCE_DATA_DOWNLOAD_REQUEST_FAILURE = 'GET_FINANCE_DATA_DOWNLOAD_REQUEST_FAILURE';

export const SET_COMPANY_DATA_FROM_CLIST = 'SET_COMPANY_DATA_FROM_CLIST_FOR_LOCAL_STORAGE';
export const SET_TRUST_DATA_FROM_TLIST = 'SET_TRUST_DATA_FROM_TLIST';

export const UPLOAD_FINANCE_DATA_REQUEST_INIT = 'UPLOAD_FINANCE_DATA_REQUEST_INIT';
export const UPLOAD_FINANCE_DATA_REQUEST_DONE = 'UPLOAD_FINANCE_DATA_REQUEST_DONE';
export const UPLOAD_FINANCE_DATA_REQUEST_FAILURE = 'UPLOAD_FINANCE_DATA_REQUEST_FAILURE';

export const SAVE_FINANCE_DATA_REQUEST_INIT = 'SAVE_FINANCE_DATA_REQUEST_INIT';
export const SAVE_FINANCE_DATA_REQUEST_DONE = 'SAVE_FINANCE_DATA_REQUEST_DONE';
export const SAVE_FINANCE_DATA_REQUEST_FAILURE = 'SAVE_FINANCE_DATA_REQUEST_FAILURE';

export const DELETE_FINANCE_MON_YR_DATA_REQUEST_INIT = 'DELETE_FINANCE_MON_YR_DATA_REQUEST_INIT';
export const DELETE_FINANCE_MON_YR_DATA_REQUEST_DONE = 'DELETE_FINANCE_MON_YR_DATA_REQUEST_DONE';
export const DELETE_FINANCE_MON_YR_DATA_REQUEST_FAILURE = 'DELETE_FINANCE_MON_YR_DATA_REQUEST_FAILURE';

export const DELETE_COMPANY_FINANCE_DATA_REQUEST_INIT = 'DELETE_COMPANY_FINANCE_DATA_REQUEST_INIT';
export const DELETE_COMPANY_FINANCE_DATA_REQUEST_DONE = 'DELETE_COMPANY_FINANCE_DATA_REQUEST_DONE';
export const DELETE_COMPANY_FINANCE_DATA_REQUEST_FAILURE = 'DELETE_COMPANY_FINANCE_DATA_REQUEST_FAILURE';

export const UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT = 'UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT';
export const UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE = 'UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE';
export const UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE = 'UPLOAD_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE';

export const DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT = 'DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_INIT';
export const DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE = 'DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_DONE';
export const DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE = 'DELETE_FINANCE_ANNUAL_REPORT_DATA_REQUEST_FAILURE';

export const CHECK_CIN_CODE_ISVALID_REQUEST_INIT = 'CHECK_CIN_CODE_ISVALID_REQUEST_INIT';
export const CHECK_CIN_CODE_ISVALID_REQUEST_DONE = 'CHECK_CIN_CODE_ISVALID_REQUEST_DONE';
export const CHECK_CIN_CODE_ISVALID_REQUEST_FAILURE = 'CHECK_CIN_CODE_ISVALID_REQUEST_FAILURE';

export const GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_INIT = 'GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_INIT';
export const GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_DONE = 'GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_DONE';
export const GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_FAILURE = 'GET_PARENT_COMPANY_OPTIONS_DATA_REQUEST_FAILURE';