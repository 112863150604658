export const GET_USERS_LIST_REQUEST = 'GET_USERS_LIST_REQUEST';
export const GET_USERS_LIST_DONE = 'GET_USERS_LIST_DONE';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';

export const SET_USER_TAB_CONTENT = 'SET_USER_TAB_CONTENT';


export const GET_MANAGE_USER_DATA_BY_ID_REQ_INIT = 'GET_MANAGE_USER_DATA_BY_ID_REQ_INIT';
export const GET_MANAGE_USER_DATA_BY_ID_DONE = 'GET_MANAGE_USER_DATA_BY_ID_DONE';
export const GET_MANAGE_USER_DATA_BY_ID_FAILURE = 'GET_MANAGE_USER_DATA_BY_ID_FAILURE';


export const SAVE_MANAGE_USER_DATA_REQ_INIT = 'SAVE_MANAGE_USER_DATA_REQ_INIT';
export const SAVE_MANAGE_USER_DATA_REQ_DONE = 'SAVE_MANAGE_USER_DATA_REQ_DONE';
export const SAVE_MANAGE_USER_DATA_REQ_FAILURE = 'SAVE_MANAGE_USER_DATA_REQ_FAILURE';
export const SAVE_MANAGE_USER_DATA_REQ_RESET = 'SAVE_MANAGE_USER_DATA_REQ_RESET';


export const GET_GROUP_ADMIN_CREATED_USERS_LIST_REQ_INIT = 'GET_GROUP_ADMIN_CREATED_USERS_LIST_REQ_INIT';
export const GET_GROUP_ADMIN_CREATED_USERS_LIST_REQ_DONE = 'GET_GROUP_ADMIN_CREATED_USERS_LIST_REQ_DONE';
export const GET_GROUP_ADMIN_CREATED_USERS_LIST_REQ_FAILURE = 'GET_GROUP_ADMIN_CREATED_USERS_LIST_REQ_FAILURE';

export const GET_SUBSCRIPTION_PERIOD_DATA_BY_ID_DONE = 'GET_SUBSCRIPTION_PERIOD_DATA_BY_ID_DONE';
export const GET_SUBSCRIPTION_PERIOD_DATA_BY_ID_FAILURE = 'GET_SUBSCRIPTION_PERIOD_DATA_BY_ID_FAILURE';
export const SAVE_SUBSCRIPTION_PERIOD_REQ_INIT = 'SAVE_SUBSCRIPTION_PERIOD_REQ_INIT';
export const SAVE_SUBSCRIPTION_PERIOD_REQ_DONE = 'SAVE_SUBSCRIPTION_PERIOD_REQ_DONE';
export const SAVE_SUBSCRIPTION_PERIOD_REQ_FAILURE = 'SAVE_SUBSCRIPTION_PERIOD_REQ_FAILURE';

export const DELETE_SUBSCRIPTION_PERIOD_REQ_INIT = 'DELETE_SUBSCRIPTION_PERIOD_REQ_INIT';
export const DELETE_SUBSCRIPTION_PERIOD_REQ_DONE = 'DELETE_SUBSCRIPTION_PERIOD_REQ_DONE';
export const DELETE_SUBSCRIPTION_PERIOD_REQ_FAILURE = 'DELETE_SUBSCRIPTION_PERIOD_REQ_FAILURE';

export const GENERATE_PASSWORD = 'GENERATE_PASSWORD';

//Added 

export const GET_MANAGE_USERS_INITIALIZE_DATA_INIT = 'GET_MANAGE_USERS_INITIALIZE_DATA_INIT';
export const GET_MANAGE_USERS_INITIALIZE_DATA_DONE = 'GET_MANAGE_USERS_INITIALIZE_DATA_DONE';
export const GET_MANAGE_USERS_INITIALIZE_DATA_FAILURE = 'GET_MANAGE_USERS_INITIALIZE_DATA_FAILURE';

//GET_ADD_USERS_INITIALIZE_DATA_INIT
export const GET_ADD_USERS_INITIALIZE_DATA_INIT = 'GET_ADD_USERS_INITIALIZE_DATA_INIT'; 
export const GET_ADD_USERS_INITIALIZE_DATA_DONE = 'GET_ADD_USERS_INITIALIZE_DATA_DONE'; 
export const GET_ADD_USERS_INITIALIZE_DATA_FAILURE = 'GET_ADD_USERS_INITIALIZE_DATA_FAILURE'; 


export const CHECK_MANAGE_USER_EMAIL_ID_REQ_INIT = 'CHECK_MANAGE_USER_EMAIL_ID_REQ_INIT';
export const CHECK_MANAGE_USER_EMAIL_ID_REQ_VALID = 'CHECK_MANAGE_USER_EMAIL_ID_REQ_VALID';
export const CHECK_MANAGE_USER_EMAIL_ID_REQ_INVALID = 'CHECK_MANAGE_USER_EMAIL_ID_REQ_INVALID';


export const GET_MANAGE_USERS_LIST_DATA_REQUEST_INIT = 'GET_MANAGE_USERS_LIST_DATA_REQUEST_INIT';
export const GET_MANAGE_USERS_LIST_DATA_REQUEST_DONE = 'GET_MANAGE_USERS_LIST_DATA_REQUEST_DONE';
export const GET_MANAGE_USERS_LIST_DATA_REQUEST_FAILURE = 'GET_MANAGE_USERS_LIST_DATA_REQUEST_FAILURE';


//For Group Admin Users

export const GET_MANAGE_GROUP_USERS_LIST_DATA_REQUEST_INIT = 'GET_MANAGE_GROUP_USERS_LIST_DATA_REQUEST_INIT';
export const GET_MANAGE_GROUP_USERS_LIST_DATA_REQUEST_DONE = 'GET_MANAGE_GROUP_USERS_LIST_DATA_REQUEST_DONE';
export const GET_MANAGE_GROUP_USERS_LIST_DATA_REQUEST_FAILURE = 'GET_MANAGE_GROUP_USERS_LIST_DATA_REQUEST_FAILURE';

export const GET_MANAGE_GROUP_USERS_PAGE_INIT_DATA_REQUEST_INIT = 'GET_MANAGE_GROUP_USERS_PAGE_INIT_DATA_REQUEST_INIT';
export const GET_MANAGE_GROUP_USERS_PAGE_INIT_DATA_REQUEST_DONE = 'GET_MANAGE_GROUP_USERS_PAGE_INIT_DATA_REQUEST_DONE';
export const GET_MANAGE_GROUP_USERS_PAGE_INIT_DATA_REQUEST_FAILURE = 'GET_MANAGE_GROUP_USERS_PAGE_INIT_DATA_REQUEST_FAILURE';

export const GET_MANAGE_GROUP_USERS_PAGE_ADD_USER_DATA_REQUEST_INIT = 'GET_MANAGE_GROUP_USERS_PAGE_ADD_USER_DATA_REQUEST_INIT';
export const GET_MANAGE_GROUP_USERS_PAGE_ADD_USER_DATA_REQUEST_DONE = 'GET_MANAGE_GROUP_USERS_PAGE_ADD_USER_DATA_REQUEST_DONE';
export const GET_MANAGE_GROUP_USERS_PAGE_ADD_USER_DATA_REQUEST_FAILURE = 'GET_MANAGE_GROUP_USERS_PAGE_ADD_USER_DATA_REQUEST_FAILURE';

export const MANAGE_GROUP_USERS_PAGE_DELETE_USER_DATA_REQUEST_INIT = 'MANAGE_GROUP_USERS_PAGE_DELETE_USER_DATA_REQUEST_INIT';
export const MANAGE_GROUP_USERS_PAGE_DELETE_USER_DATA_REQUEST_DONE = 'MANAGE_GROUP_USERS_PAGE_DELETE_USER_DATA_REQUEST_DONE';
export const MANAGE_GROUP_USERS_PAGE_DELETE_USER_DATA_REQUEST_FAILURE = 'MANAGE_GROUP_USERS_PAGE_DELETE_USER_DATA_REQUEST_FAILURE';

export const CHECK_EMAIL_ID_REQUEST_CLEAR = 'CHECK_EMAIL_ID_REQUEST_CLEAR';
export const CHECK_EMAIL_ID_REQUEST_INIT = 'CHECK_EMAIL_ID_REQUEST_INIT';
export const CHECK_EMAIL_ID_REQUEST_DONE = 'CHECK_EMAIL_ID_REQUEST_DONE';
export const CHECK_EMAIL_ID_REQUEST_FAILURE = 'CHECK_EMAIL_ID_REQUEST_FAILURE';

export const CHANGE_USER_AUTO_GENERATE_PWD_REQUEST_INIT = 'CHANGE_USER_AUTO_GENERATE_PWD_REQUEST_INIT';
export const CHANGE_USER_AUTO_GENERATE_PWD_REQUEST_DONE = 'CHANGE_USER_AUTO_GENERATE_PWD_REQUEST_DONE';
export const CHANGE_USER_AUTO_GENERATE_PWD_REQUEST_FAILURE = 'CHANGE_USER_AUTO_GENERATE_PWD_REQUEST_FAILURE';

export const GET_USER_BASED_SUBSCRIPTION_LIST_FOR_GROUPADMIN_REQUEST_INIT = 'GET_USER_BASED_SUBSCRIPTION_LIST_FOR_GROUPADMIN_REQUEST_INIT';
export const GET_USER_BASED_SUBSCRIPTION_LIST_FOR_GROUPADMIN_REQUEST_DONE = 'GET_USER_BASED_SUBSCRIPTION_LIST_FOR_GROUPADMIN_REQUEST_DONE';
export const GET_USER_BASED_SUBSCRIPTION_LIST_FOR_GROUPADMIN_REQUEST_FAILURE = 'GET_USER_BASED_SUBSCRIPTION_LIST_FOR_GROUPADMIN_REQUEST_FAILURE';

export const SAVE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_INIT = 'SAVE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_INIT';
export const SAVE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_DONE = 'SAVE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_DONE';
export const SAVE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_FAILURE = 'SAVE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_FAILURE';

export const DELETE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_INIT = 'DELETE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_INIT';
export const DELETE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_DONE = 'DELETE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_DONE';
export const DELETE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_FAILURE = 'DELETE_USER_BASED_SUBSCRIPTION_DATA_FOR_GROUPADMIN_REQUEST_FAILURE';

export const PAGE_ID = {
  '/dashboard': 1,
  '/manageusers': 2,
  '/lausers': 0,
  '/cgroup': 3.1,
  '/clist': 3.2,
  '/cadd': 3.3,
  '/cupdatelog': 3.4,
  '/cfinance': 0,
  '/rdprojects': 4.1,
  '/unpublishproject': 4.2,
  '/rdqry': 4.3,
  '/rdupdatelog': 4.4,
  '/myprojectlist': 4.5,
  '/myqrylist': 4.6,
  '/rdprojectsview': 4.7,
  '/rdprojectsadd': 4.8,
  '/wsprojects': 5.1,
  '/wsunpublishproject': 5.2,
  '/wsqry': 5.3,
  '/wsupdatelog': 5.4,
  '/myprojectlistwater': 5.5,
  '/myqrylistws': 5.6,
  '/wsprojectsview': 5.7,
  '/wsprojectsadd': 5.8,
  '/userupdatelog': 7,
  '/metroprojects': 0,
  '/test': 0,
};