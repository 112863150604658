import { Request } from 'utils';
import { getLoginUserID, getLoginUserTypeId } from './utils/functions.utils';

export const prepareSubmenu = async (maxAttempts = 3, retryDelay = 1000, maxWaitTime = 5000, pollInterval = 500) => {
  const waitForLoginData = async () => {
    const startTime = Date.now();
    while (!getLoginUserID() || !getLoginUserTypeId()) {
      if (Date.now() - startTime > maxWaitTime) {
        throw new Error("Login data not available within timeout.");
      }
      await new Promise(resolve => setTimeout(resolve, pollInterval));
    }
  };

  try {
    // Wait for login data to be available
    await waitForLoginData();
  } catch (error) {
    console.error("Failed to retrieve login data:", error);
    return [];
  }

  for (let attempt = 1; attempt <= maxAttempts; attempt++) {
    try {
      const response = await Request.post("getMyRoadGroup", {}, 2);

      if (response?.meta?.data) {
        const data = response.meta.data;

        const submenus = data.map((item) => {
          if (item.id_road_project !== null) {
            try {
              item.id_road_project = JSON.parse(item.id_road_project);
            } catch (parseError) {
              console.error("Error parsing id_road_project:", parseError);
            }
          }

          return {
            label: item.group_name || "Unnamed Group",
            url: `/myprojectlist/${item.id_my_group}`,
            key: item.id_my_group,
            count: item.id_road_project?.length ? item.id_road_project.length : ''
          };
        });

        const prioritizedItem = submenus.find((item) => item.key === "1");
        const otherItems = submenus.filter((item) => item.key !== "1");
        const orderedSubmenus = prioritizedItem ? [prioritizedItem, ...otherItems] : submenus;
        return orderedSubmenus;
      } else {
        console.warn("No data received for road groups.");
        return [];
      }
    } catch (error) {
      console.error(`Attempt ${attempt} - Error fetching road groups:`, error);

      if (attempt < maxAttempts) {
        await new Promise(resolve => setTimeout(resolve, retryDelay)); // Wait before retrying
      } else {
        console.error("Max retry attempts reached. Unable to fetch road groups.");
        return [];
      }
    }
  }
};
