import React, {Fragment, useState, Component} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {connect, useSelector, shallowEqual} from 'react-redux';
import RouterLink from './react-metismenu-router-link';
import MetisMenu from 'react-metismenu';
import _ from 'lodash';
import { SidebarUserbox } from '../../layout-components';
import { prepareSubmenu } from '../../menuUtils';
import { withRouter } from 'react-router';
import { setActiveLinkTo } from 'reducers/ThemeOptions';

class SidebarMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      count_project: this.props.count,
      sidebarMenuContent: [],
      groupCount: localStorage.getItem("my_group_count")
    }
    // this.sidebarMenuContent = this.setMenuContent(this.props.actions,this.props.road_project_count,  this.props.my_ws_count,this.props.road_qry_count,this.props.ws_project_count);
  }

  // data_count = (document.getElementsByClassName("count-my-project").value = this.props.count)
  async componentDidMount() {
    const sidebarMenuContent = await this.setMenuContent(
      this.props.actions,
      this.props.my_group_count,
      this.props.road_project_count,
      this.props.my_ws_count,
      this.props.road_qry_count,
      this.props.ws_project_count
    );
    this.setState({ sidebarMenuContent });
    
    if(this.props.actions.MENU.ROAD_MY_PROJECT === true) {
      if(document.getElementById('count-road-project')) {
        document.getElementById('count-road-project').innerHTML = this.props.road_project_count ? this.props.road_project_count : 0  ;
      }
    }
    if(this.props.actions.MENU.WS_MY_PROJECT === true) {
      if(document.getElementById('count-ws-project')) {
        document.getElementById('count-ws-project').innerHTML = this.props.ws_project_count ? this.props.ws_project_count : 0  ;
      }
       
    }


    if(this.props.actions.MENU.ROAD_MY_QUERY === true){
      if(document.getElementById('count-road-query')) {
      document.getElementById('count-road-query').innerHTML = this.props.road_qry_count ? this.props.road_qry_count : 0;
      }
    }
    if(this.props.actions.MENU.WS_MY_QUERY === true){
      if(document.getElementById('count-ws-query')) {
      document.getElementById('count-ws-query').innerHTML =  this.props.my_ws_count ? this.props.my_ws_count : 0;
      }
    }

  }

  async componentDidUpdate(prevProps) {

    if (prevProps.groupCount !== this.props.groupCount) {
      this.setState({ groupCount: this.props.groupCount });
      const sidebarMenuContent = await this.setMenuContent(
        this.props.actions,
        this.props.my_group_count,
        this.props.road_project_count,
        this.props.my_ws_count,
        this.props.road_qry_count,
        this.props.ws_project_count
      );
      this.setState({ sidebarMenuContent });
      // setTimeout(() => {
      //   this.props.setActiveLinkTo(this.props.location.pathname);
      //   console.log(this.props.location.pathname);
      // }, 1000)
      this.props.setActiveLinkTo(this.props.location.pathname);

      
      // if (this.props.location.pathname === prevProps.location.pathname) {
      //   
      // }
    }   
    
    if(this.props.actions.MENU.ROAD_MY_PROJECT === true) {
      if(document.getElementById('count-road-project')) {
      document.getElementById('count-road-project').innerHTML = this.props.road_project_count ? this.props.road_project_count : 0  ;
      }
    }
    if(this.props.actions.MENU.WS_MY_PROJECT === true) {
      if(document.getElementById('count-ws-project')) {
        document.getElementById('count-ws-project').innerHTML = this.props.ws_project_count ? this.props.ws_project_count : 0  ;
      }
    }
    if(this.props.actions.MENU.ROAD_MY_QUERY === true){
      if(document.getElementById('count-road-query')) {
      document.getElementById('count-road-query').innerHTML = localStorage.getItem("my_query_count") ? localStorage.getItem("my_query_count") : 0;
      }
    }
    if(this.props.actions.MENU.WS_MY_QUERY === true){
      if(document.getElementById('count-ws-query')) {
        document.getElementById('count-ws-query').innerHTML = localStorage.getItem("my_ws_count") ? localStorage.getItem("my_ws_count") : 0;
      }
    }

  }

  setMenuContent = async (Action,project_count) => {
    const menuContent = [];
    if (Action.MENU.DASHBOARD) {
      menuContent.push({ "label": "Dashboard", "icon": "fa-light fa-chart-tree-map", "to": "/dashboard"});
    }
    if (Action.MENU.MANAGE_USERS) {
      menuContent.push({ "label": "Manage Users", "icon": "fa-light fa-users", "to": "/manageusers" });
    }
    if (Action.MENU.MANAGE_LOCAL_ADMIN_USERS) {
      menuContent.push({ "label": "Manage Users", "icon": "fa-light fa-users", "to": "/lausers" });//For Group Manage Users
    }
    if (Action.MENU.COMPANY||Action.MENU.COMPANY_UPDATE_LOG || Action.MENU.COMPANY_GROUP) {

      let obj = {
        "label": "Company",
        "icon": "fa-sharp fa-light fa-buildings",
        "content": []
      };
      if (Action.MENU.COMPANY_GROUP) {
        obj.content.push({ "label": "Company Group", "to": "/cgroup" });
      }
      if (Action.MENU.COMPANY) {
        obj.content.push({ "label": "Company List", "to": "/clist" });
      }
      if (Action.MENU.COMPANY_UPDATE_LOG) {
        obj.content.push({ "label": "Update Log", "to": "/cupdatelog" });
      }
      menuContent.push(obj);
    }

    if (Action.MENU.TRUST) {

      let obj = {
        "label": "Trust",
        "icon": "fa-light fa-handshake",
        "content": []
      };
      if (Action.MENU.TRUST) {
        obj.content.push({ "label": "Published", "to": "/publishedtrust" });
      }
      if (Action.MENU.TRUST) {
        obj.content.push({ "label": "Unpublished", "to": "/unpublishedtrust" });
      }
      menuContent.push(obj);
    }

    if (Action.MENU.ROAD_PROJECT_LIST || Action.MENU.ROAD_QUERY || Action.MENU.ROAD_UPDATE_LOG || Action.MENU.ROAD_MY_PROJECT || Action.MENU.ROAD_MY_QUERY || Action.MENU.ROAD_PROJECT_UNPUBLISH) {
      let obj = {
        "label": "Road",
        "icon": "fa-light fa-road",
        "content": []

      };
      if (Action.MENU.ROAD_PROJECT_LIST) {
        obj.content.push({ "label": "Projects", "icon":"fa-light fa-diagram-project", "to": "/rdprojects" });
      }
      if (Action.MENU.ROAD_PROJECT_UNPUBLISH) {
        obj.content.push({ "label":"Projects - Unpublished", "to": "/unpublishproject"});
      }
      if (Action.MENU.ROAD_QUERY) {
        obj.content.push({ "label": "Query", "to": "/rdqry" });
      }
      if (Action.MENU.ROAD_UPDATE_LOG) {
        obj.content.push({ "label": "Update Log", "to": "/rdupdatelog" });
      }
      // if (Action.MENU.ROAD_MY_PROJECT) {
      //   obj.content.push({ "label":<>My Projects <span className='count-box' id='count-road-project'></span></>, "to": "/myprojectlist"});
      // }
      if (Action.MENU.ROAD_MY_QUERY) {
        obj.content.push({ "label": <>My Queries <span className='count-box' id='count-road-query' ></span></>, "to": "/myqrylist"});
      }
      menuContent.push(obj);
    }

    let obj = {
      label: 'My Projects',
      icon: 'fa-light fa-road',
      content: [],
    };

    try {
      const submenus = await prepareSubmenu();
      console.log(submenus);
      
      submenus.forEach((submenu) => {
        obj.content.push({
          label: (
            <>
              {submenu.label}{' '}
              <span className="count-box" id={`count-${submenu.key}`}>{submenu.count}</span>
            </>
          ),
          to: submenu.url,
        });
      });

      menuContent.push(obj);
    } catch (error) {
      console.error('Error preparing menu:', error);
    }

    // if (Action.MENU.WS_PROJECT_LIST) {
    //   let obj = {
    //     "label": "Water & Sanitation",
    //     "icon": "fa-light fa-hand-holding-droplet",
    //     "content": []

    //   };
    //   if (Action.MENU.WS_PROJECT_LIST) {
    //     obj.content.push({ "label": "Projects", "to": "/wsprojects" });
    //   }
    //   if (Action.MENU.WS_PROJECT_UNPUBLISH) {
    //     obj.content.push({ "label":"Projects - Unpublished", "to": "/wsunpublishproject"});
    //   }
    //   if (Action.MENU.WS_QUERY) {
    //     obj.content.push({ "label":"Query" , "to": "/wsqry"});
    //   }
    //   if (Action.MENU.WS_UPDATE_LOG) {
    //     obj.content.push({ "label": "Update Log", "to": "/wsupdatelog" });
    //   }
    //   if (Action.MENU.WS_MY_PROJECT) {
    //     obj.content.push({ "label":<>My Projects <span className='count-box' id='count-ws-project'></span></> , "to": "/myprojectlistwater"});
    //   }
    //   if (Action.MENU.WS_MY_QUERY) {
    //     obj.content.push({ "label": <>My Queries <span className='count-box' id='count-ws-query' ></span></>, "to": "/myqrylistws"});
    //   }
    //   menuContent.push(obj);

    // }

    // if (Action.MENU.METRO_PROJECT_LIST) {
    //   menuContent.push({ "label": "Metro", "icon": "fa-light fa-train-subway-tunnel", "content": [{ "label": "Projects", "to": "/metroprojects" }] });
    // }

    // if (Action.MENU.WS_PROJECT_LIST) {
    //   menuContent.push({ "label": "My Project List", "icon": "fa-regular fa-rectangle-history", "to": "/myprojectlist" });

    // }
    // if (Action.MENU.MANAGE_USERS) {
    //   menuContent.push({ "label": "User Log", "icon": "fa-light fa-list", "to": "/userupdatelog" });
    // }

    return menuContent;
  }


  activeLinkMenu = () => {

  }

  render() {

    let { sidebarUserbox } = this.props;
    const { sidebarMenuContent } = this.state;

    return (
        <Fragment>
          <PerfectScrollbar>
            {sidebarUserbox && <SidebarUserbox /> }
            <div className="sidebar-navigation">
                <div className="sidebar-header">
                  { /** <span>Navigation menu</span> **/ }
                </div>

                <MetisMenu ref="menu" activeLinkTo={this.props.activeLinkToMenu}  content={sidebarMenuContent} LinkComponent={RouterLink} activeLinkFromLocation iconNamePrefix="" noBuiltInClassNames={true}
                classNameItemActive="active" classNameIcon="sidebar-icon" iconNameStateVisible="sidebar-icon-indicator" iconNameStateHidden="sidebar-icon-indicator"
                classNameItemHasVisibleChild="submenu-open" classNameItemHasActiveChild="active" classNameStateIcon="pe-7s-angle-down"   />
            </div>
          </PerfectScrollbar>
      </Fragment>
      );
    }
}


const mapStateToProps = state => ({
  actions: _.has(state.Authentication, 'user.action') ? state.Authentication.user.action : {},
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  activeLinkToMenu: state.ThemeOptions.activeLinkTo || '',
  groupCount: state.Road.groupCount
});

const mapDispatchToProps = dispatch => ({
setActiveLinkTo: (link) => dispatch(setActiveLinkTo(link)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarMenu));
