import React, { Fragment, forwardRef, useRef } from 'react';
import Select from 'react-select';
import Datepicker from 'react-datepicker';
import Autocomplete from 'react-autocomplete';
import cx from 'classnames';
import moment from 'moment';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Card,
  CardBody,
  Table,
  CardHeader
} from 'reactstrap';
import _ from 'lodash';
import SweetAlert from 'sweetalert2-react';
import jQuery from 'jquery';
import { PropagateLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { DatetimeRangePicker } from 'components';
import Skeleton from 'react-loading-skeleton';

export const ReactSelect = (props) => {
  const { options, selectedId, handleChange, name, isInValid } = props;
  let defaultSelectedObj =
    selectedId > 0 ? _.find(options, { value: selectedId }) : null;

  const _handleChange = (slectedOptions) => {
    handleChange(slectedOptions.value, name);
  };

  let stylOptions = {
    minHeight: 35,
    height: 35,
    borderRadius: 2,
    boxShadow: 'none',
    fontSize: '0.82rem',
    borderColor: isInValid ? 'red' : '#d1d2db',
    '&:hover': {
      borderColor: '#9297da'
    }
  };

  let optionStyle = {
    fontSize: '0.82rem',
    paddingTop: '5px',
    paddingBottom: '5px'
  };
  if (
    jQuery(window).outerWidth() >= 1200 &&
    jQuery(window).outerWidth() <= 1440
  ) {
    stylOptions = Object.assign({}, stylOptions, {
      minHeight: 28,
      height: 28,
      fontSize: '0.72rem'
    });
    optionStyle = Object.assign({}, optionStyle, {
      fontSize: '0.72rem'
    });
  }

  // options - [{value: 1, label: 'test'}]
  return (
    <Select
      className="reactSelect"
      value={defaultSelectedObj}
      label="Single select"
      options={options}
      onChange={_handleChange}
      styles={{
        /***menu: (base, state) => ({
                    ...base,
                    borderColor: state.isFocused ? '#9297da' : '#d1d2db'
                }),***/
        menu: (base, state) => ({
          ...base,
          marginTop: '2px',
          borderRadius: '2px'
        }),
        control: (base, state) => ({
          ...base,
          ...stylOptions
        }),
        option: (base, state) => ({
          ...base,
          ...optionStyle
          /***backgroundColor: state.isSelected ? '#3c44b1' : 'white',
                    '&:hover': {
                        //color: '#000',
                        backgroundColor: state.isSelected ? '#3c44b1' : '#eaeaea'
                    }***/
        }),
        indicatorsContainer: (base, state) => ({
          ...base
        })
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: '2px',
        borderWidth: 1,
        colors: {
          ...theme.colors,
          primary25: 'rgba(60,68,177,0.15)',
          primary50: 'rgba(60,68,177,0.15)',
          primary: '#3c44b1'
        }
      })}
    />
  );
};

export function DateRangePicker(props) {
  const {
    fromDate,
    toDate,
    handleChange,
    isValidFrom,
    isValidTo,
    dateFormat,
    min,
    max
  } = props;

  const startDate =
    !_.isNil(fromDate) && !_.isEmpty(fromDate)
      ? moment(fromDate).toDate()
      : null;
  const endDate =
    !_.isNil(toDate) && !_.isEmpty(toDate) ? moment(toDate).toDate() : null;

  const minDate = !_.isNil(min) ? moment(min).toDate() : null;
  const maxDate = !_.isNil(max) ? moment(max).toDate() : null;

  const CustomizeInput = forwardRef((props, ref) => {
    const { value, onClick, placeholder, isvalid } = props;
    return (
      <Input
        type="text"
        bsSize="sm"
        value={value}
        placeholder={placeholder}
        invalid={isvalid}
        onClick={onClick}
        readOnly={true}
        ref={ref}
      />
    );
  });

  const _dateFormat =
    !_.isNil(dateFormat) && !_.isEmpty(dateFormat) ? dateFormat : 'dd-MMM-yyyy';

  return (
    <Fragment>
      <InputGroup className="subscription-period-group">
        <Datepicker
          customInput={<CustomizeInput isvalid={isValidFrom} />}
          name="start_date"
          selectsStart
          showMonthDropdown
          showYearDropdown
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) =>
            handleChange(moment(date).format('YYYY-MM-DD'), 'start_date')
          }
          dateFormat={_dateFormat}
          placeholderText="From Date"
          className="date-range-picker"
        />
        <InputGroupAddon addonType="prepend"> To </InputGroupAddon>
        <Datepicker
          customInput={<CustomizeInput isvalid={isValidTo} />}
          name="end_date"
          selectsEnd
          showMonthDropdown
          showYearDropdown
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={maxDate}
          onChange={(date) =>
            handleChange(moment(date).format('YYYY-MM-DD'), 'end_date')
          }
          dateFormat={_dateFormat}
          placeholderText="To Date"
          className="date-range-picker"
        />
      </InputGroup>
    </Fragment>
  );
}

export function DateInputPicker(props) {
  const { selected, name, handleChange, isvalid, placeholder, ref } = props;
  const selectedDate =
    !_.isNil(selected) && !_.isEmpty(selected)
      ? moment(selected).toDate()
      : null;

  const CustomizeInput = React.forwardRef((props, ref) => {
    const { value, onClick, placeholder, isvalid } = props;
    return (
      <Input
        type="text"
        bsSize="sm"
        value={value}
        placeholder={placeholder}
        invalid={isvalid}
        onClick={onClick}
        readOnly={true}
        forwardRef={ref}
      />
    );
  });

  return (
    <Fragment>
      <Datepicker
        customInput={<CustomizeInput isvalid={false} />}
        onChange={(date) =>
          handleChange(moment(date).format('YYYY-MM-DD'), name)
        }
        selected={selectedDate}
        showMonthDropdown
        showYearDropdown
        dateFormat="dd-MMM-yyyy"
        placeholderText={placeholder}
        className="w-100"
        ref={ref}
      />
    </Fragment>
  );
}

export function InputReactAutoComplete(props) {
  const {
    items,
    value,
    handleChange,
    isvalid,
    placeholder,
    name,
    idFieldName,
    come
  } = props;
  //const [state, setState] = useState(value || '');

  /***const items = [
        { value: 'foo', label: 'foo' },
        { value: 'bar', label: 'bar' },
        { value: 'baz', label: 'baz' },
    ];***/

  const _onChange = (val,e) => {
    handleChange(val, name,e);
    let org_obj = _.find(items, ['label', _.trim(val)]);
    if (_.isUndefined(org_obj) || _.isNull(org_obj) || _.isEmpty(org_obj)) {
      handleChange(-1, idFieldName,e);
    } else {
      handleChange(+org_obj.value, idFieldName,e);
    }
  };

  const _modify_change=(e)=>{
    handleChange(e);
  }

  return (
    <Autocomplete
      value={value}
      inputProps={{
        className: cx('form-control form-control-sm', {
          invalid: isvalid || false
        }),
        placeholder: placeholder,
        name: name
      }}
      wrapperStyle={{ position: 'relative', display: 'block' }}
      items={items}
      getItemValue={(item) => item.label}
      shouldItemRender={(item, val) => {
        //return item.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
        if (!_.isNil(item.label) && !_.isNil(val)) {
          return item.label.toLowerCase().indexOf(val.toLowerCase()) > -1;
        } else {
          return false;
        }
      }}
      onChange={(event, val) => come?_modify_change(val):_onChange(val,event)}
      onSelect={(val) =>come?_modify_change(val): _onChange(val)}
      renderMenu={(children) => (
        <div
          className={cx('menu react-auto-complete--menu shadow', {
            'd-none': children.length === 0
          })}>
          {children}
        </div>
      )}
      renderItem={(item, isHighlighted) => {
        return (
          <div
            className={`item react-auto-complete--options ${
              isHighlighted ? 'highlighted' : ''
            }`}
            key={item.label + '_' + item.value}>
            {item.label}
          </div>
        );
      }}
    />
    
  );
}

export function InputReactAutoCompleteWithID(props) {
  const {
    items,
    value,
    handleChange,
    isvalid,
    placeholder,
    name,
    idFieldName,
    come,
    clear
  } = props;

  const _onChange = (val, e) => {
    const normalizedVal = val?.normalize("NFKC");
    const trimmedVal = normalizedVal?.replace(/^\s+|\s+$/g, "");
    let org_obj = _.find(items, ['label', normalizedVal]);
    if (_.isUndefined(org_obj) || _.isNull(org_obj) || _.isEmpty(org_obj)) {
      handleChange({ label: trimmedVal, value: '' }, idFieldName, e);
    } else {
      handleChange({ label: trimmedVal, value: org_obj.value }, idFieldName, e);
    }
  };

  const _modify_change = (e) => {
    handleChange(e);
  };

  return (
    <div className='position-relative'>
    <Autocomplete
      value={value}
      inputProps={{
        className: cx('form-control form-control-sm', {
          invalid: isvalid || false
        }),
        placeholder: placeholder,
        name: name
      }}
      wrapperStyle={{ position: 'relative', display: 'block' }}
      items={items}
      getItemValue={(item) => item.label}
      shouldItemRender={(item, val) => {
        return item.label.toLowerCase().includes(val.toLowerCase());
      }}
      onChange={(event, val) => {
        come ? _modify_change(val) : _onChange(val, event);
      }}
      onSelect={(val) => {
        come ? _modify_change(val) : _onChange(val, null);
      }}
      renderMenu={(children) => (
        <div
          className={cx('menu react-auto-complete--menu shadow', {
            'd-none': children.length === 0
          })}
        >
          {children}
        </div>
      )}
      renderItem={(item, isHighlighted) => {
        return (
          <div
            className={`item react-auto-complete--options ${isHighlighted ? 'highlighted' : ''}`}
            key={item.label + '_' + item.value}
          >
            {item.label}
          </div>
        );
      }}
    />
    {!_.isEmpty(value) &&
    <span className='search-clear' onClick={clear}><FontAwesomeIcon icon={['fa', 'times']} /></span>}
    </div>
  );
}


export function Sweetalert(props) {
  return <SweetAlert {...props} />;
}

export function NoDataFound(props) {
  const { children, className, ...rest } = props;
  let childrenTxt = children ? (
    children
  ) : (
    <h5 className="display-6"> No Data Found </h5>
  );
  //card-list-box text-center py-5 my-5
  return (
    <Card
      className={cx(
        'card-list-box',
        'finance-card-container',
        'no-data-found-card',
        'text-center',
        'py-5',
        className
      )}
      {...rest}>
      {childrenTxt}
    </Card>
  );
}

export function AjaxLoader(props) {
  const { isOpen, imgSrc, altText, toggle, pageLoader, loaderText } = props;
  if (isOpen) {
    if (!jQuery('body').hasClass('modal-open'))
      jQuery('body').addClass('overflow-hidden');
  } else {
    if (jQuery('body').hasClass('overflow-hidden'))
      jQuery('body').removeClass('overflow-hidden');
  }

  let _pageLoader = _.isNil(pageLoader) ? false : pageLoader;
  let content = <img src={imgSrc} alt={altText || ''} />;
  if (_pageLoader) {
    content = (
      <div className="page-loader">
        <PropagateLoader
          color={'var(--primary)'}
          loading={true}
          css={{ marginLeft: '90px', marginTop: '15px' }}
        />{' '}
        {!_.isNil(loaderText) ? (
          <p style={{ textAlign: 'center', paddingTop: '15px' }}>
            {loaderText}
          </p>
        ) : null}{' '}
      </div>
    );
  }

  return (
    <div className={cx('overlay', 'ajaxloader', { active: isOpen })}>
      {content}
    </div>
  );
}

/***export function OpenModalViewContent(props) {
    const { backdrop,  } = props;
    return (<Modal zIndex={2000} centered isOpen={modal5} toggle={toggle5}></Modal>);
}***/

export const ReactDateTime = {
  DateField(props) {
    const inputEl = useRef(null);
    const { value, id, placeholder, handleChange, dateFormat,index, ...others } = props;

    const _handleChange = (e) => {
      handleChange(e.valueOf(), id,index);
      _.delay(() => {
        inputEl.current.closeCalendar();
      }, 100);
    };

    const renderInput = (props, openCalendar, closeCalendar) => {
      function clear() {
        props.onChange({ target: { value: ' ' } });
      }
      return (
        <InputGroup>
          <Input bsSize="sm" {...props} />
          <InputGroupAddon addonType="append">
            {props.value != '' ? (
              <Button
                color="danger"
                size="sm"
                type="button"
                onClick={clear}
                style={{ transform: 'none', padding: '0.25rem 0.5rem' }}>
                <FontAwesomeIcon icon={['fa', 'times']} />
              </Button>
            ) : null}
            <Button
              color="primary"
              size="sm"
              type="button"
              onClick={openCalendar}
              style={{ transform: 'none', padding: '0.25rem 0.5rem' }}>
              <FontAwesomeIcon icon={['far', 'calendar-alt']} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      );
    };

    return (
      <DateTime
        renderInput={renderInput}
        dateFormat={dateFormat||"MMM-YYYY"}
        value={value}
        ref={inputEl}
        timeFormat={false}
        inputProps={{
          className: 'form-control form-control-sm rounded-0',
          id: id,
          placeholder: placeholder || 'MMM-YYYY',
          readOnly: true
        }}
        onChange={(e) => _handleChange(e)}
        {...others}
      />
    );

    /****return (
      <InputGroup>
        <InputGroupAddon addonType="append">
          <DateTime
            dateFormat="MMM-YYYY"
            value={value}
            ref={inputEl}
            timeFormat={false}
            inputProps={{
              className: 'form-control form-control-sm rounded-0',
              id: id,
              placeholder: placeholder || 'MMM-YYYY',
              readOnly: true
            }}
            onChange={(e) => _handleChange(e)}
            {...others}
          />
          <Label
            htmlFor={id}
            className="input-group-text rounded-0 border-left-0">
            <FontAwesomeIcon icon={['far', 'calendar-alt']} />
          </Label>
        </InputGroupAddon>
      </InputGroup>
    );****/
  },
  DateRangeField(props) {
    const inputEl = useRef(null);
    const { inputProps, handleChange, id, ...others } = props;

    const _handleChange = (e) => {
      let data = {
        start_date: moment(e.start.valueOf()).unix(),
        end_date: !_.isNaN(e.end.valueOf())
          ? moment(e.end).unix()
          : moment(e.start).unix()
      };
      handleChange(data, id);
    };

    let defaultClassNames = 'form-control form-control-sm rounded-0';
    if (!_.isNil(inputProps)) {
      if (_.has(inputProps, 'className') && !_.isEmpty(inputProps.className)) {
        defaultClassNames += ' ' + inputProps.className;
        _.unset(inputProps, 'className');
      } else if (
        _.has(inputProps, 'className') &&
        _.isEmpty(_.trim(inputProps.className))
      ) {
        _.unset(inputProps, 'className');
      }
    }

    const _inputProps = Object.assign(
      {},
      { className: defaultClassNames },
      !_.isNil(inputProps) ? inputProps : {}
    );
    return (
      <DatetimeRangePicker
        ref={inputEl}
        className="subscription-period-group"
        onChange={(e) => _handleChange(e)}
        inputProps={_inputProps}
        {...others}
      />
    );
  }
};

export const Loader = {
  TableSkeleton(props) {
    const { noOfRows, tableOnly, hideCardHeader } = props;
    let max = noOfRows || 10;
    let isShowTableOnly = tableOnly || false;
    let isHideCardHeader = hideCardHeader || false;

    const TableContainer = (
      <Table bordered>
        <thead>
          <tr>
            <th style={{ width: '5%' }} key={'th_1'}>
              {' '}
              <Skeleton height={25} />{' '}
            </th>
            <th style={{ width: '30%' }} key={'th_2'}>
              {' '}
              <Skeleton height={25} />{' '}
            </th>
            <th style={{ width: '20%' }} key={'th_3'}>
              {' '}
              <Skeleton height={25} />{' '}
            </th>
            <th style={{ width: '20%' }} key={'th_4'}>
              {' '}
              <Skeleton height={25} />{' '}
            </th>
            <th style={{ width: '15%' }} key={'th_5'}>
              {' '}
              <Skeleton height={25} />{' '}
            </th>
            <th style={{ width: '10%' }} key={'th_6'}>
              {' '}
              <Skeleton height={25} />{' '}
            </th>
          </tr>
        </thead>
        <tbody>
          {_.map(_.range(0, max), (indx) => (
            <tr key={'tbody_tr_' + indx}>
              <td key={'td_' + indx + '_1'}>
                <Skeleton />
              </td>
              <td key={'td_' + indx + '_2'}>
                <Skeleton />
              </td>
              <td key={'td_' + indx + '_3'}>
                <Skeleton />
              </td>
              <td key={'td_' + indx + '_4'}>
                <Skeleton />
              </td>
              <td key={'td_' + indx + '_5'}>
                <Skeleton />
              </td>
              <td key={'td_' + indx + '_6'}>
                <Skeleton />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (isShowTableOnly) {
      return TableContainer;
    }

    return (
      <Card className="card-box mb-3 rounded-0 border-0">
        {isHideCardHeader ? null : (
          <CardHeader>
            <div style={{ width: '60%' }}>
              <Skeleton height={30} />
            </div>
          </CardHeader>
        )}
        <CardBody>{TableContainer}</CardBody>
      </Card>
    );
  }
};
