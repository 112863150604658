import React, { Component, Fragment, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import Select, { Option } from 'rc-select';
import { Button, UncontrolledTooltip, Row, Col, Card, CardBody, Label, InputGroup, Input, InputGroupAddon } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader, Pagination } from 'components';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { useHistory } from "react-router";

function AvailableProject(props) {

    const navigate = useHistory();
    const [projectdata, setProjectdata] = useState([props.projectData])
    
    const viewlogdata = (obj) => {
        props.close();
        let url = window.location.href;

        if (url.includes('rdprojectsview')) {
            window.open('/rdprojectsview', { rname: 'update', value: obj })
        }
        else {
            if (props.rname === "road") {
                navigate.push('/rdprojectsview', { rname: 'update', value: obj });
            } else {
                navigate.push('/rdprojectsview', { cname: 'update', value: obj });
            }
        }

    }

    const columnObj = [
        { Header: '#', accessor: '', style: { width: '3%' }, className: 'text-center', Cell: ({ row }) => <div > {row.index + 1}</div> },
        { Header: 'Stretch Name', accessor: 'road_stretch_name', style: { width: '20%' }, className: 'text-left' },
        { Header: 'Mode', accessor: 'project_mode', style: { width: '5%' }, className: 'text-left' },
        { Header: 'Status', accessor: 'project_status', style: { width: '17%' }, className: 'text-left' },
        {
            Header: 'Total Project Cost/km (Rs.Crs)', accessor: 'tpc_per_km', style: { width: '10%', textAlign: "right" }, className: 'text-right', Cell: ({ row }) => {
                return (
                    <div>{row.values.tpc_per_km}</div>
                )
            }
        },
        {
            Header: 'Total Project Cost  (Rs.Crs)', accessor: 'tpc', style: { width: '10%', textAlign: "right" }, className: 'text-right', Cell: ({ row }) => {
                return (
                    <div>{row.values.tpc}</div>
                )
            }
        },
    ];
    columnObj.push({
        Header: '', accessor: 'id_road_project', style: { width: '4%' }, id: 'action_columns', Cell: ({ row }) => {
            let _row = row.original;

            let data = 1

            return (
                <div className=" justify-content-between align-items-center flex-nowrap action-col-cell">

                    <Fragment>
                        <UncontrolledTooltip trigger="hover" popperClassName={'tooltip-info'} placement="top" container="body" target={'view_more_btn_company' + _row.id_road_project
                        }>  {'View Details'}
                        </UncontrolledTooltip>

                        <Button onClick={() => viewlogdata(_row.id_road_project, data)} outline size="sm" className="btn-sm-icon btn-outline-slack border-1" id={'view_more_btn_company' + _row.id_road_project}>
                            <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fas', 'eye']} className="opacity-8 font-size-xs" />
                            </span>
                        </Button>
                    </Fragment>

                </div>
            );
        },
        sortable: false,
        className: 'text-center',
    });



    const columns = useMemo(() => columnObj, []);
    let res_data = props.projectData ? props.projectData : [];

    let data = React.useMemo(() => res_data, [res_data]);


    const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow, nexPage, previousPage, gotoPage, setPageSize, state, setGlobalFilter } = useTable({ columns, data }, useGlobalFilter, usePagination,);


    const { pageIndex, pageSize, globalFilter } = state

    const onPageChange = (current, pageSize) => {
        gotoPage(current - 1)

    }

    const changePage = (PageChange, size) => {
        setPageSize(size)
    }
    useEffect(() => {
        changePage(1, 15)
    }, [])




    return (
        <Fragment>
            <div className="px-4 py-2 page-drawer-header-container">
                <div className="text-left">
                    <h6 className="mb-0 text-black" style={{ marginTop: '5px' }}>Available Projects for the {props?.ctype ? props.ctype : ''} <span className='fw-bold' style={{color:"#0c1159"}}>{props.ctype === "group" ? props.company?.company_group_name : props.company?.company_name}</span></h6>
                </div>
            </div>
            <div className="divider" />
            <Card className="card-box mb-5 rounded-2 border-0">
                <CardBody className="py-2">
                  

                        <div className='d-flex justify-content-end mb-2'>
                        {data.length > 15 &&
                            <InputGroup style={{ maxWidth: '250px' }} className="filter-input-group">
                                <Input placeholder="Search" className="form-control form-control-sm rounded-0" value={globalFilter || ""} onChange={(e) => setGlobalFilter(e.target.value)} style={{ width: '275px' }} />
                                <InputGroupAddon addonType="append"><Button size="sm" style={{ backgroundColor: '#dadada' }} className="rounded-0 border-0 transform-none box-shadow-none"><FontAwesomeIcon icon={['fas', 'search']} size="sm" /></Button></InputGroupAddon>
                            </InputGroup>
                                }
                            {props?.ctype !== "trust" &&
                            <Fragment>
                                <Button size="sm" color="info" id="export_data_btn" onClick={()=> props.downloadExcel(globalFilter)} className="ml-2 add-update-btn-page-title">
                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon icon={['fas', 'file-excel']} className="opacity-8 font-size-xs" /> Export
                                    </span>
                                </Button>
                                <UncontrolledTooltip popperClassName={'tooltip-info'} placement="top" target="export_data_btn">
                                    Export Data To Excel
                                </UncontrolledTooltip>
                            </Fragment>}
                        </div>
            

                    <div style={{ height: data.length > 15 ? "700px" : "auto", overflowY: "auto" }}>
                        <table {...getTableProps({ className: "table table-bordered table-hover" })}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps(
                                    )}>
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps({ className: column.className ? column.className : '', style: column.style ? column.style : {} })}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {_.size(page) === 0 ? (
                                    <tr>
                                        <td colSpan={_.size(columns)} className="text-center">No Data Found</td>
                                    </tr>
                                ) : page.map((row, i) => {

                                    prepareRow(row)
                                    return (
                                        <>
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps({ className: cell.column.className ? cell.column.className : '' })}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    {data.length > 15 &&
                        <Pagination
                            selectComponentClass={Select}
                            pageSizeOptions={['25', '50', '75', '100']}
                            showTotal={(total, [from, to]) => { let _from = +from < 0 ? 1 : from; let _to = to > -1 ? to : total; return (<>{_from} - {_to} Of {total}</>) }}
                            defaultPageSize={15}
                            pageSize={pageSize}

                            defaultCurrent={1}
                            // current={page1.current}
                            total={data.length}
                            onChange={onPageChange}
                            onShowSizeChange={changePage}
                            style={{ borderRadius: '4px', fontSize: '12px' }}
                            wrapperClassName={cx('p-2 pagination-container')}
                        />}

                </CardBody>
            </Card>
        </Fragment>
    );

}
export default AvailableProject;